:root {

  --primary-color: #020230;
  --primary1-color: #2d2d50;
  --primary2-color: #374151;
  --blue-primary-color: #3581BD;
  --blue-light: #EBF4FA;
  --blue-primary1-color: #06a4d3;
  --blue-primary2-color: #14468A;
  --blue-primary3-color: #3a8ed0;
  --skyblue-color: #25CAD2;
  --gray-primary-color: #6b7280;
  --gray-primary1-color: #A1A1AA;
  --gray-primary2-color: #9CA3AF;
  --gray-primary3-color: #F9FAFB;
  --gray-primary4-color: #84818A;
  --gray-primary5-color: #505050;
  --gray-dark-color: #6c757d;
  --border-color: #E5E7EB;
  --border1-color: #dee2e6;
  --white-color: #ffffff;
  --white1-color: #F9FAFB;
  --white2-color: #F8FAFC;
  --text: #020230;  /* common on light and dark */
  --text2: #141619; 
  --text3: #2E2C34;
  --black: #000000;
  --black1: #0F172A;
  --black2: #111827;
  --black3: #333;
  --red: #e11d48;
  --red-light: #ffe4e6;
  --red-light2: #fda4af;
  --green: #2e8f3f;
  --green-light: #0D9488;
  --green-light1: #CCFBF1;
  --green-light2: #14b8a6;
  --green-light3: #5A7052;
  --green-light4: #DEF2D6;
  --orange: #D97706;
  --orange-light: #FEF3C7;
  --theme-text: #020230;
  --white3-color: #EEF3F8;
  
}

[data-theme=dark] {
  --theme-text: #fff;
}

/*** 

====================================================================
	Reset
====================================================================

***/
html {
  /* scroll-behavior: smooth; */
}

::-webkit-scrollbar {
  /* width: 12px; */
}
::-webkit-scrollbar-thumb {
  background: #020230;
  border-radius: 5px;
  border: 3px solid #fff;
}

::-webkit-scrollbar-track {
  background: #fff;
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body.arabic .app-phone-input *{
  direction: ltr;
}

.react-tel-input .special-label{
  display: none;
}

.sign {
  align-items: center;
  background: #020230;
  display: flex;
  height: 100%;
  justify-content: center;
  /* min-height: calc(100vh - 410px); */
  min-height: calc(100vh - 282px);
  padding: 20px 0;
}

.signInput .react-tel-input .form-control {
  width: 100%;
}

.grecaptcha-badge{
  display: none !important;
}

/*** 
  ====================================================================
      Global Settings
  ====================================================================
   ***/
body {
  font-family: "Space Grotesk", sans-serif;
  font-weight: normal;
  font-size: 18px;
  /* line-height: 30px; */
  color: var(--theme-text);
  background: #f1f2f7;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

body.arabic .nav__inner-lang-title span{
  right: unset;
  left: 0;
}

body.arabic * {
  direction: rtl;
  unicode-bidi: embed;
  font-family: 'Cairo', sans-serif !important;
  line-height: unset
}
body.arabic h2, body.arabic.h1 {
  line-height: 68px !important;
}

body.arabic .flip > *{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: "FlipH";
  -webkit-filter: FlipH;
  filter: FlipH;
  
}

body.infoAct {
  overflow: hidden;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--black);
}

.auto__container {
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 60px;
  width: 100%;
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  letter-spacing: -0.02em;
}

h1.example {
  text-align: center;
}

h2 {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  letter-spacing: -2px;
  color: var(--black3);
}

h3 {
  font-weight: 600;
  font-size: 34px;
  /* line-height: 32px; */
  color: #374151;
}

h5 {
  font-weight: bold;
  font-size: 22px;
  /* line-height: 28px; */
  color: var(--black3);
}

h6 {
  font-weight: normal;
  font-size: 20px;
  /* line-height: 30px; */
  color: var(--black2);
}

p span {
  color: #3a8ed0;
}

.row{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}


.signInput__inner {
  position: relative;
}
.signInput__inner input {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary2-color);
  padding: 7px 8px;
}

.error__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--red);
  /* display: block; */
}

.search-input input{
  flex-grow: 1;
  margin-inline-start: 15px;
}
.search-input{
  height: 40px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(--gray-primary2-color);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.button {
  outline: none;
  height: 40px;
  padding: 0 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: unset;
  box-sizing: border-box;
  font-family: 'Inter';
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  font-weight: 500;
}

.button svg{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:focus {
  outline: none;
}

.button:hover {
  opacity: 0.8;
}

button[disabled=disabled]:hover, button:disabled:hover {
  cursor: unset;
  opacity: 1;
}

.button.primary {
  border: 1px solid #25CAD2;
  background-color: #25CAD2;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
}

.button.gradient{
  background: linear-gradient(265.18deg, var(--skyblue-color) -0.01%, var(--blue-primary2-color) 99.01%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.button.transparent {
  border: 1px solid #667085;
  background-color: transparent;
  color: #fff;
}
.button.white{
  border: 1px solid #fff;
  background-color: white;
  color: var(--primary-color);
}
.button.skyBlue{
  border: 1px solid var(--skyblue-color);
  background-color: var(--skyblue-color);
  color: var(--white-color);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
}

.footer {
  background: #020230;
  padding: 60px 0 0 0;
}
.footer__inner-logo {
  width: 112px;
  margin: 0 auto 32px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__inner-logo img {
  width: 100%;
  height: 43px;
}
.footer__inner-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
}
.footer__inner-nav-link {
  margin: 0 16px;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e4e7ec;
}

.copyright {
  padding: 32px 0;
  border-top: 1px solid #475467;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #020230;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.copyright__text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}
.copyright .social-icons{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyright i{
  color: #fff;
  padding: 0 7px;
}
.copyright__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.copyright__nav-link {
  display: block;
  margin-left: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}

body.arabic .copyright__nav-link {
  margin: 0 16px 0 0;
}

.header {
  /* padding: 25px 0;
  background: #020230;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; */
  padding: 25px 0;
  background: #020230;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__inner-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header__inner-logo img {
  height: 43px;
  width: 112px;
}
.header.sticky {
  position: fixed;
  animation: sticky 0.2s linear;
}

@-webkit-keyframes sticky {
  0% {
    top: -60px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes sticky {
  0% {
    top: -60px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.subnav.hover-effect .subnav-content {
  
  transition: all .3s ease-in-out;
  /* opacity: 1; */
  visibility: visible;
}
.navbar a:hover, .subnav.hover-effect .subnavbtn {
  background-color: var(--primary-color);
}
.nav__inner-link svg{
  transition: all .1s ease-in-out;
  
}
.navbar a:hover, .subnav.hover-effect .nav__inner-link > svg{
  transform: rotate(-180deg);
  transition: all .1s ease-in-out;
}

.subnav-content svg{
  width: 40px;
  height: 40px;
}
.subnav-inner .nav__inner-link{
  margin: 0 !important;
}
.subnav-inner{
  display: flex;
  flex-wrap: wrap;
  background-color: var(--primary-color);
  padding: 30px;
  padding-top: 0;

}
.arabic .subnav-content {
  right: unset;
  left: 0;
}
.subnav-content {
  /* display: none; */
  /* opacity: 0; */
  /* height: 0; */
  visibility: hidden;
  position: absolute;
  right: 0px;
  width: 75%;
  z-index: 1;
  top: 30px;
  border-radius: 10px;
  transition: all .3s ease-in-out;
  border-top: 30px solid transparent;
}
.subnav-content a {
  color: var(--white-color);
  margin-right: 0;
}

.submenu-card-container > div{
  margin-inline-start: 20px;
}
.submenu-card-container {
  max-width: 311px;
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  padding: 31px;
}
.submenu-card-container h5{
  font-weight: 700;
  font-size: 18px;
  color: var(--white-color);
}

.submenu-card-container p{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--white-color);
}

.nav{
  flex: 1;
}

.nav__inner > .row:first-child{
  flex-grow: 1;
  justify-content: flex-start;
  margin-inline-start: 85px;
  
}

.nav__inner {
  display: flex;
  align-items: center;
  /* position: relative; */
}
.nav__inner-link {
  display: block;
  margin-inline-end: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #fff;
  cursor: pointer;
}
.nav__inner-lang {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #fff;
  cursor: pointer;
  margin-inline-start: 40px;
}
.nav__inner-lang-title {
  
  display: flex;
  align-items: center;
}
.nav__inner-lang-title img {
  width: 20px;
  height: 20px;
  margin-inline-end: 5px;
}
.nav__inner-lang-title span {
  display: block;
  margin-inline-start: 5px;
  width: 12px;
  height: 8px;
  background: url(../public/images/icons/chevron-bottom.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.nav__inner-lang-content {
  position: absolute;
  top: 100%;
  right: 0;
  /* width: 100px; */
  background: #020230;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
.nav__inner-lang-content-link {
  padding: 10px;
  text-align: right;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
.nav__inner-lang-content-link:hover {
  opacity: 0.8;
}
.nav__inner-lang:hover .nav__inner-lang-content {
  max-height: 500px;
}
.nav__inner .button {
  margin-inline-start: 24px;
}

/* body.arabic .nav__inner-link {
  margin: 0 0 0 24px;
} */

/* body.arabic .nav__inner-lang-title span {
  margin: 0 5px 0 0;
} */
/* body.arabic .nav__inner .button {
  margin: 0 24px 0 0;
} */

.burger {
  display: none;
}



.checkbox {
  position: relative;
}
.checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.checkbox input:checked ~ label::before {
  border: 1px solid #3581bd;
  background: #3581bd;
}
.checkbox input:checked ~ label::after {
  opacity: 1;
}
.checkbox label {
  padding-left: 24px;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #374151;
}
.checkbox label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  transition: all 0.2s ease-in-out 0s;
  border-radius: 4px;
}
.checkbox label:after {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 8px;
  height: 4px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}


.intro{
  color: var(--white-color);
  padding-top: 25px;
  text-align: center;
  background: url(../public/images/intro-bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-color: var(--primary-color);
  align-items: center;
  display: flex;
  flex-direction: column;
}
.intro .button{
  margin-bottom: 10px;
}
.intro p{
  margin: 16px 0; 
}

/* .intro > div{
  max-width: 980px;
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
} */

.intro-footer__inner {
  position: relative;
}
.intro-footer__inner p{
  margin-bottom: 0;
  position: absolute;
  top: -10px;
  text-align: center;
  width: 100%;
  
}
.intro-footer__inner img{
  max-width: 966.09px;
  max-height: 617.35px;
}


.platform-section-card {
  
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  height: 260px;
  background: var(--white-color);
  box-sizing: border-box;
  box-shadow: 0px 4px 18px -2px rgb(17 24 39 / 8%);
  border-radius: 24px;
  margin: 10px;
}
.platform-section-cards {
  display: grid;
  grid-template-columns: 50% 50%;	
}
.platform-section-heading-container{
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.platform-section-heading{
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--primary-color);
}

.platform-section-text{
  color: var(--black1);
  margin: 20px 0;
}

.feature,.platform-section {
  padding: 65px 0;
  background: var(--white1-color);
}

.platform-icon-bg {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  /* background-image: linear-gradient(to right, var(--primary-color) 0%, var(--primary1-color) 100%); */
  background-color: var(--primary-color);
  margin-inline-end: 15px;
}
.platform-icon-bg img{
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.chat-section__inner{
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 930px;
  margin: auto;
  padding: 40px 0;
}
.chat-section__inner h3{
  color: var(--white-color);
  line-height: 60px;
  margin-bottom: 20px;
}
.api,.favorite,.chat-section {
  overflow: hidden;
  background: var(--primary-color);
}

.api__inner{
  padding: 30px 0;
}

.api__inner, .favorite__inner{
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.api__inner-content label, .favorite__inner-content label{
  text-align: start;
  color: var(--skyblue-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.api__inner-content p, .favorite__inner-content p{
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  opacity: 0.8;

}
.api__inner-content{
  width: 50%;
  max-width: 485px;
  text-align: start;
  /* background-color: green; */
}
.api__inner-image {
  width: 50%;
  /* flex: 1; */
  /* background: url(../public/images/intro.png); */
  /* background-size: 100%; */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  /* background-position-x: right; */
  /* background-position-y: center; */

}

.api__inner-content .section-header p{
  opacity: .7;
}

.favorite__inner-content {
  color: var(--white-color);
  width: 50%;
  margin-inline-end: 10%;
}
.arabic .favorite__inner-image {
  margin-right: unset;
  margin-left: -200px;
}
.favorite__inner-image {
  /* width: calc(48% + 200px); */
  /* margin-right: -200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: -100px;
  /* margin-bottom: -200px; */
}
.api__inner-image img,.favorite__inner-image img {
  width: 100%;
  height: 100%;
}

.section-header{
  text-align: center;
}



.section-header p{
  color: var(--gray-primary5-color);
  margin-bottom: 20px;
}

.section-header h2{
  margin-bottom: 20px;
  color: var(--black);
}
.section-header h2.white, .section-header p.white, .api__inner-content h2{
  color: var(--white-color);
  text-align: start;
}

.favorite__inner-apps {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.favorite__inner-apps-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 15px;
}
.favorite__inner-apps-item img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 160px;
}

.feature {
  padding: 65px 0;
  background: #f1f2f7;
}
.feature__inner p,.feature__inner h2{
  text-align: center;
}
.feature h2 {
  margin-bottom: 50px;
}
.feature__inner{
  display: flex;
  align-items: center;
  flex-direction: column;
  
}
.feature__inner-row {
  display: flex;
  width: 100%;
}

.featureCard {
  width: calc(33.3% - 30px);
  margin: 0 15px;
  box-shadow: 0px 4px 18px -2px rgba(17, 24, 39, 0.08);
  border-radius: 24px;
  overflow: hidden;
  background-color: #fff;
}
.featureCard__image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featureCard__image img {
  width: 100%;
  height: 100%;
}
.featureCard__body {
  padding: 42px 32px;
  background: #fff;
}
.featureCard__body h5 {
  display: flex;
  align-items: center;
  text-align: start;
}
.featureCard__body h5 span {
  font-weight: bold;
  font-size: 50px;
  line-height: 64px;
  letter-spacing: -3px;
  color: var(--black3);
  margin-inline-end: 25px;
  display: inline-block;
}


.contact-form form{
  margin-top: 20px;
}
.contact-form p{
  margin-bottom: 0;
}
.contact-form {
  max-width: 850px;
  margin: auto;
  padding: 66px 0;
}

.contact-form-auth.active{
  max-height: 1000px;
  transition: max-height .3s ease-in;
}

.contact-form-auth{
  max-height: 0;
  transition: max-height .3s ease-out;
  overflow: hidden;
}

.contact-form-auth .button {
  margin-top: 30px;
  width: 100%;
}
.row .signInput:first-child {
  margin-inline-end: 10px;
}
.contact-form-auth .signInput, .demo-page-form .signInput {
  margin-bottom: 16px;
  flex: 1
}

.signInput label {
  
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-primary-color);
  display: block;
  margin-bottom: 8px;
}

.footer {
  background: var(--primary-color);
  /* padding: 60px 0 0 0; */
}
.footer__inner-logo {
  width: 112px;
  margin: 0 auto 32px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__inner-logo img {
  width: 100%;
}
.footer__inner-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
}
.footer__inner-nav-link {
  margin: 0 16px;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e4e7ec;
}



.pricing-section-cards h2{
  margin-bottom: 10px;
}
.pricing-section-cards{
  max-width: 950px;
  margin: auto;
  padding: 75px 0;
}

.plan_card__inner:last-child {
  margin-inline-end: 0;
  
}
.plan_card__inner {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  /* max-width: 405px; */
  margin: 0 auto;
  animation: 0.2s linear sign;
  width: 100%;
  font-family: 'Inter';
  margin-inline-end: 10px;
}

@-webkit-keyframes sign {
  0% {
    margin-top: 60px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes sign {
  0% {
    margin-top: 60px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.plan_card__header .plan_card_title{
  background: linear-gradient(265.18deg, var(--skyblue-color) -0.01%, var(--blue-primary2-color) 99.01%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 20px;
}
.plan_card__header {
  position: relative;
  display: flex;
  flex-direction: column;
}
.plan_card__header .plan_card_number strong{

  font-size: 24px;
  line-height: 25px;
  color: var(--black2);
  font-weight: 700;
}
.plan_card__header .plan_card_number{
  font-weight: 500;
  font-size: 15px;
  line-height: 11px;
  color: var(--gray-primary-color);
}

.most-popular-plan-container{
  background-color: var(--white2-color);
  border-radius: 100px;
  padding: 0px 6px;
  /* margin-top: 5px; */
  align-self: flex-end;
  /* position: absolute; */
  /* top: 55px; */
  font-size: 10px;
  font-weight: 700;
  min-height: 20px;

}
.most-popular-plan{
  background: linear-gradient(265.18deg, var(--skyblue-color) -0.01%, var(--blue-primary2-color) 99.01%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 20px;

}
.plan_card_desc{
  
  color: var(--gray-primary-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  margin-top: 10px;
  margin-bottom: 17px;

}
.plan_card_points-lable .plan_card_points-left, .plan_card_points-lable .plan_card_points-right{
  flex: 1;
  height: 1px;
  background-color: var(--gray-primary2-color);
}
.plan_card_points-lable label{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-primary2-color);
  margin: 17px;
}
.plan_card_points li svg{
  margin-inline-end: 8px;

}
.plan_card_points li{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary2-color);
  margin-bottom: 16px;
}
.pricing-cards{
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.trial {
  padding: 50px 0;
  background: var(--primary-color);
}
.trial__inner{
  max-width: 768px;
  margin: auto;
}
.trial__inner .row{
justify-content: center;
}
.trial__inner .section-header *{
  text-align: center !important;
}
.trial__inner .row .button {
  margin: 0 6px;
}

.contact-management-header{
  padding-top: 50px;
}
.contact-management-header .favorite__inner-content{
  margin-inline-end: 30px;
  width: 45%;
}
.contact-management-header .favorite__inner-content .button.transparent{
  margin-top: 15px;
  margin-bottom: 5px;
}

.manage-relationship .contact-form{
  padding: 0;
}
.manage-relationship{
  padding: 96px 0;
  background-color: #f1f2f7;
  
}
.manage-relationship__inner{
  max-width: 768px;
  margin: auto;
}

.manage-relationship__inner p{
  margin-bottom: 0;
}


.market-place{
  height: 325px;
  background: url(../public/images/market_place.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;  
  flex-direction: column;
}
.market-place .section-header *{
  text-align: center !important;
}
.market-place .section-header{
  max-width: 768px;
}
.activities-sections{
  margin: 32px;
}
.activities-sections__inner{
  align-items: flex-start;
}

.activities-sections__inner .side-section {
  margin-top: 24px;
}

.activities-sections__inner aside{
  max-width: 226px;
  margin-inline-end: 15px;
  background-color: var(--white-color);
  padding: 32px;
  font-family: 'Inter';
  border-radius: 12px;

}

.activities-sections__inner aside label{
  font-weight: 500;
  font-size: 16px;
  color: var(--primary2-color);
}

.activities-sections__inner aside li{
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 4px;
}

.activities-sections-body{
  flex-grow: 1;
  background-color: var(--white-color);
  padding: 32px;
  border-radius: 12px;
}
.activities-sections-body label{
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
  margin: 5px;
  margin-bottom: 14px;
  margin-top: 14px;
}

.activities-sections-body h6{
  font-weight: 700;
  font-size: 18px;
  color: var(--primary1-color);
}
.activities-sections-body p{
  font-size: 14px;
  line-height: 18px;
  color: var(--primary1-color);
}

.activities-sections-body .categories-card:nth-child(3n){
  margin-inline-end: 0;
}

.activities-sections-body .categories-card{
  width: calc(33.33% - 12px);
  height: 284px;
  border: 1px solid #E4E4E7;
  border-radius: 16px; 
  overflow: hidden;
  margin-inline-end: 15px;
}
.activities-sections-body .categories-card-bg-img{
  height: 190px;
  background-color: #06a4d3;
}
.activities-sections-body .categories-card__inner{
  padding: 16px;
}

.activities-sections-body .categories-card-long:nth-child(3n) {
  margin-inline-end: 0;

}
/* .activities-sections-body .categories-card-long:first-child {
  margin-inline-start: 0;
} */
.activities-sections-body .categories-card-long {
  width: calc(33.3% - 12px);
  height: 110px;
  /* overflow: scroll; */
  border: 1px solid #E4E4E7;
  filter: drop-shadow(0px 2px 4px rgba(31, 41, 55, 0.06)) drop-shadow(0px 4px 6px rgba(31, 41, 55, 0.1));
  border-radius: 16px;
  padding: 12px;
  margin: 9px 0;
  margin-inline-end: 18px;
  box-sizing: border-box;
}

.activities-sections-body .categories-card-long-img{
  width: 62px;
  height: 62px;
  background: #18181B;
  border: 1px solid #E4E4E7;
  border-radius: 1000px;
}

.categories-card-long__inner{
  margin-inline-start: 7px;
  flex: 1;
}

.categories-cards{
  margin-top: 15px;
}
.categories-cards .row{
  flex-wrap: wrap;
  justify-content: flex-start;
}

.manage-relationship__inner > button {
  margin: auto;
  margin-top: 64px;
}


.deal-tracking .section-header, .task-management .section-header{
  max-width: 825px;
  margin: auto;
}
.banner-header{
  min-height: 465px;
  background-color: #020230;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-header img{
  max-height: 500px;
  width: 100%;
  object-fit: contain;
  margin: 30px 0;
}

.deal-tracking-body{
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: #fff;
}
.image-text-layout{
  display: flex;
  align-items: flex-start;
  margin: 110px 0;
}
.image-text-layout-image-view img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image-text-layout-image-view{
  flex: 2;
  margin: 0 50px;
  /* margin-right: 50px; */
}
.image-text-layout-text-view{
  flex: 1;
  margin-top: 50px;
}
.image-text-layout-text-view .heading{
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #000000;
  margin-bottom: 15px;
}

.image-text-layout-text-view .p{
  font-size: 21px;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: #000000;
}

.paralex-scrolling{
  margin-top: 64px;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.paralex-scrolling-content{
  position: relative;
  width: 40%;
  height: 100%;
}
.paralex-scrolling-content section{
  position: relative;
  height: 100vh;
  max-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.section-wrapper{
  max-width: 400px;
}

.paralex-scrolling-images{
  width: 60%;
  max-width: 632px;
  height: 100vh;
  max-height: 500px;
  display: flex;
  align-items: center;
  /* transform: scale(.8) translateX(-70px); */
  position: sticky;
  top: calc(50% - 250px);
}

.paralex-scrolling-images img.active{
  height: 100%;
  width: 100%;
  /* opacity: 1; */
  /* transition: opacity .5s ease-in-out; */
  display: block;
}
.paralex-scrolling-images img{
  object-fit: contain;
  /* height: 0;
  width: 0; */
  display: none;
  /* opacity: 0; */
  /* transition: opacity .5s ease-in-out; */
}


.report-cards-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 14px;
  border-radius: 12px;
  margin: 60px 0;
}

.report-card{
  width: calc(50% - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin: 10px;
  /* overflow: scroll; */
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  box-sizing: border-box;
}
.report-card .report-card-image img {
  width:  100%;
  height: 100%;
  border-radius: 100px;
}

.report-card .report-card-image {
  width:  45px;
  height: 45px;
  border: 1px solid var(--border-color);
  border-radius: 100px;
  overflow: hidden;
  margin-inline-end: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white3-color);
}
.report-card-body{
  flex: 1;
  height: 80px;
  /* overflow-y: scroll; */
}
/* 
.report-card-body::-webkit-scrollbar {
  width: 1px;
}

.report-card-body::-webkit-scrollbar-thumb{
  border: unset;
} */

.report-card > div {
  font-family: 'Space Grotesk';
  color: var(--primary1-color);
}

.report-card h3{
  font-size: 18px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.report-card p {
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: start;
  margin-bottom: 0;
  margin-top: 5px;
}
.paralex-scrolling-content .image-text-layout-image-view{
  display: none
}
@media (max-width: 992px) {
  .report-cards-container{
    flex-direction: column;
  }
  .report-card{
    width: calc(100% - 20px);
  }
  .paralex-scrolling{
    margin-top: 0;
  }
  .paralex-scrolling-content section{
    margin-bottom: 30px;
    max-height: fit-content;
    height: fit-content;
  }
  .paralex-scrolling-content{
    width: 100%;
    margin-top: 30px;
  }
  .paralex-scrolling-content .image-text-layout-image-view{
    display: block;
  }
  .paralex-scrolling-images{
    display: none;
  }
  .banner-header{
    min-height: 200px ;
  }
  .deal-tracking-body{
    margin-top: 46px;
    margin-bottom: 46px;
  }
  .image-text-layout{
    margin: 40px 0;
    flex-direction: column;
    align-items: center;
  }
  .image-text-layout-text-view{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    order: 1;
  }
  .image-text-layout-image-view{
    order: 2;
  }
  .pricing-cards{
    flex-direction: column;
  }
  .chat-section__inner h3{
    line-height: 30px;
  }
  .plan_card__inner{
    max-width: 100%;
    margin-bottom: 15px;
    margin-inline-end: 0;
  }
  .manage-relationship{
    padding: 66px 16px;
  }
  .manage-relationship__inner > button {
    margin: auto;
    margin-top: 20px;
  }
  .subnav:hover .subnav-content{
    display: flex;
    transition: all .3s ease-in-out;
  } 
  .subnav-content{
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    border-top: 0;
    display: none;
    transition: all .3s ease-in-out;
  }
  .contact-management-header{
    padding-top: 0;
  }
  .subnav-inner{
    padding: 0;
  }
  .featureCard__body{
    padding: 22px 12px
  }
  .featureCard__body h5 span {
    font-size: 25px;
    line-height: 30px;
    margin-inline-end: 15px;
  }
  .platform-icon-bg img{
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  .platform-icon-bg{
    height: 40px;
    width: 40px;
    border-radius: 10px;
  }
  .platform-section-card {
    padding: 20px;
  }
  .platform-section-heading{
    font-size: 25px;
  }
  h3{
    font-size: 18px;
  }
  h5{
    font-size: 16px;
  }
  h6{
    font-size: 14px;
  }

  .burger {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .burger::before {
    top: 20%;
    width: calc(100% - 0.4rem);
    height: 4px;
    background-color: #fff;
    border-radius: 0.125rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .burger::after {
    bottom: 20%;
    width: calc(100% - 0.4rem);
    height: 4px;
    background-color: #fff;
    border-radius: 0.125rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .burger.active::before {
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .burger.active::after {
    bottom: 50%;
    -webkit-transform: translate(-50%, 50%) rotate(-45deg);
    transform: translate(-50%, 50%) rotate(-45deg);
  }

  

  .nav {
    position: fixed;
    top: 72.7px;
    left: 0;
    width: 100%;
    height: calc(100% - 72.7px);
    z-index: 100;
    background: rgba(0, 0, 0, 0.4);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
  }
  .nav.active {
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .nav__inner > .row{
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    margin-inline-start: 0 !important;
    
    /* align-items: ; */
  }
  .row.nav__actions > *{
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    margin: 5px 0 !important;
    box-sizing: border-box;
  }
  .nav__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -ms-flex-direction: column;
    position: absolute;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
    top: 0;
    right: -1px;
    width: 80%;
    padding: 0;
    z-index: 1;
    padding: 20px;
    height: calc(100%);
    /* height: calc(100% - 40px); */
    overflow-y: auto;
    background: #020230;
    box-sizing: border-box;
  }
  .nav__inner-link {
    border: none;
    padding: 15px;
    display: block;
    text-align: left;
    width: unset;
    color: #fff;
    margin-right: 0;
    font-size: 16px;
    line-height: 1;
  }
  .nav__inner-lang {
    padding: 15px;
  }
  .nav__inner-lang-title {
    position: relative;
  }
  .nav__inner-lang-title span {
    position: absolute;
    top: 6px;
    right: 0;
  }
  .nav__inner-lang-content {
    position: unset;
  }
  .nav__inner-lang-content-link {
    padding: 10px 0 10px 25px;
  }
  .nav .button {
    margin: 5px 15px;
    text-align: center;
  }
  h2 {
    font-size: 25px;
    line-height: 37px;
  }
}

@media (max-width: 768px) {
  .contact-management-header .favorite__inner-content{
    width: 100%;
    margin-inline-end: 0
  }
  .feature, .platform-section {
    padding: 30px 0;
  }
  .feature__inner-row{
    flex-direction: column;
    width: 90%;
  }
  .featureCard{
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .featureCard__image{
    width: 35%;
  }
  .featureCard__body{
    flex: 1;
  }
  .platform-section-cards{
    display: block;
  }
  .platform-section-card{
    height: 200px;
  }
  .api__inner, .favorite__inner{
    flex-direction: column;
    display: block;
    padding: 30px 0;
    width: 100%;
  }
  .api__inner-image{
    background-position-x: center;
    /* height: 300px; */
    width: 100%;
  }
  .favorite__inner-content > *, .api__inner-content > *{
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .favorite__inner-image{
    display: none;
  }
  .api__inner-content,.favorite__inner-content{
    width: 100%;
    max-width: 100%;
    margin: 0;
    text-align: center;
  }
  
  .section-header h2.white, .section-header p.white, .api__inner-content h2{
    text-align: center;
  }
  .auto__container {
    padding: 0 16px;
  }
  .footer {
    padding-top: 40px 0 0 0;
  }
  .footer__inner-nav {
    margin-bottom: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer__inner-nav-link {
    margin: 16px;
  }
  .copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 24px 0;
  }
  .copyright__nav {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .copyright__text {
    margin-top: 15px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
  }
}

@media (max-width: 660px) {
  h6{
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .platform-section-heading{
    font-size: 20px;
    line-height: 25px;
  }
  .plan_card__header .row{
    flex-direction: column;
    align-items: flex-start;
  }
  h6{
    font-size: 14px;
    line-height: 17px;
  }
}



/* Static Page Style */
.static-page{
  padding: 96px 0;
  min-height: calc(100vh - 93px - 89px);
  box-sizing: border-box;
}
.static-page-content p{
  margin-top: 20px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #000000;

}
.static-page-content{
  padding: 32px;
  background: #FFFFFF;
  border-radius: 12px;
}
.static-page-heading{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}





/* demo-form */


.demo-form{
  background: url(../public/images/demoBg.jpeg);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  
}


.demo-form .header {
  background-color: rgba(0, 0, 48, 0.2);
}

.demo-page-form-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-form .demo-page-form {
  background-color: #fff;
  border-radius: 5px;
  padding: 55px;
  margin: auto;
  max-width: 500px;
  position: relative;
  top: 100px;

}

.demo-page-form-header h3{
  margin-bottom: 10px;
}
.demo-page-form-header{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  color: #6b7280;
}